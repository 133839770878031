/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Tamsta esat biškį lochas, ponas. Čia taip būna kai kelias maikes nešioji\nvienu metu. Šiaip jau čia šiandien buvo susidurta su tikėjimosi ir\npasitikėjimo pasekmėm. Aš visada sakau, kad labai pasitikiu žmonėmis,\niki kol neparodoma, kad gal ateičiai pasitikėti nebereiktų."), "\n", React.createElement(_components.p, null, "Ir iš čia išplaukia kokie tai trust-issues. Bent jau man taip sako, kad\naš trust-issues turiu, nors pats aš to kaip ir nepastebiu. Tipo\nasmeniniam gyvenime man taip porina žinovai visokie. O aš visada galvoju\napie darbą. Nesu tikras kaip ten su personal life, ale šiandien pamačiau\nkiek tas trust'inimas nervų suėda ofisėliuose."), "\n", React.createElement(_components.p, null, "Šiaip jau aš esu labai piktas žmogus. Pykstu ant visko ir dažnai. Nu,\nkaip „ant visko“? Netiesa – visada pykstu ant savęs užvis. Kai durnius\ndaro durnus dalykus, gi ant jo niekas nepyksta. Reikia pykti ant savęs,\nkad davei durniui kelią ir nusekei iš paskos. Durniui davus kelią,\ngalvoju, reiktų tada jau šalutiniu pyzdint."), "\n", React.createElement(_components.p, null, "Labiausiai pikta ant savęs už tą begalinį pasitikėjimą, kai suvoki, kad\nnu kelis kartus turėjai progą eismą sukontroliuot, pažiūrėt, ranką\niškelt ir ten kažkaip raudono švilpuko signalu pabirbint. Ale numojai\nranka ir davei durniui kelią, galvodamas, kad ten visai ne durnius."), "\n", React.createElement(_components.p, null, "Nu žinai, mūsų žodis sako, kad visi yra debilai, įskaitant ir jį. Nu kas\nyra neabejotina tiesa ir išgelbėjimas. Neverčiu kaltės ant durniaus, nes\ndurnas tai aš irgi. Ir per visą pilvą net! Bet, tipo, vistiek pikta.\nSėdi durnius suskilusioj geldoj ir pyksta, kad gelda yra gelda."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
